<template>
  <div class="sub-header">
    <div class="sub-header__wrap container">
      <h1 class="sub-header__title main-title">Маршруты</h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.sub-header {
  padding: 16px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  box-sizing: border-box;
  height: 80px;
}
</style>
